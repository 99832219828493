import cx from 'classnames'
import React, { MouseEvent, useState } from 'react'
import Button, { ButtonType } from 'src/components/Button/Button'
import DemographyBars, {
  SCROLL_TARGET_ID,
} from 'src/components/DemographyBars/DemographyBars'
import ExpertGrid from 'src/components/ExpertGrid'
import QuestionProgress from 'src/components/QuestionProgress/QuestionProgress'
import browserRoutes from 'src/routes/browser'
import { ExpertData, PopulationData, uiTexts } from 'src/util/questionData'
import styles from './Question.module.scss'
import Container from '../Container'
import { useContext } from 'react'
import NavigationContext from 'src/routes/NavigationContext'
import { useEffect } from 'react'
import AnswersContext from 'src/util/AnswersContext'
import navButton from 'src/assets/images/nav-button.svg'
import { Link, scroller } from 'react-scroll'
import { postAnswers } from 'src/api/rest'
import Statement from 'src/components/Statement'
import arrow from 'src/assets/images/arrow-forward.svg'

interface Props {
  questionNumber: number
  question: string
  lastQuestionNumber: number
  expertAnswers: ExpertData['answers']
  populationAnswers: PopulationData
  image: any
  imageAlt: string
}

type Answer = 'yes' | 'no'

export default function Question(props: Props) {
  const {
    questionNumber,
    lastQuestionNumber,
    populationAnswers,
    image,
    imageAlt,
  } = props
  const { answers, dispatch, done } = useContext(AnswersContext)
  const answer = answers[`${questionNumber}`]
  const { push, pop, current } = useContext(NavigationContext)
  const [definitionVisible, setDefinitionVisible] = useState(false)

  useEffect(() => {
    if (!current?.params?.keepScrollPosition) {
      window.scrollTo(0, 0)
    } else {
      scroller.scrollTo(SCROLL_TARGET_ID, {
        duration: 0,
        delay: 0,
      })
    }
  }, [answer, current])

  const handleNextClicked = () => {
    if (questionNumber === lastQuestionNumber) {
      if (!done) {
        const answersBody = Object.entries(answers).map(([k, v]) => ({
          questionNumber: Number(k),
          answer: v,
        }))

        postAnswers(answersBody)
          .then(() => {
            dispatch({ type: 'done', payload: {} })
          })
          .catch((e) => {
            throw e
          })
      }

      push({
        name: browserRoutes.end,
      })
    } else {
      push({
        name: browserRoutes.question,
        params: {
          questionNumber: questionNumber + 1,
        },
      })
    }
  }

  const handlePreviousClicked = () => {
    if (questionNumber > 1) {
      pop({ keepScrollPosition: false })
    }
  }

  const handleAnswerClicked = (e: MouseEvent<HTMLButtonElement>) => {
    const answer = e.currentTarget.value as Answer
    dispatch({
      type: 'answer',
      payload: { questionNumber: `${questionNumber}`, answer },
    })
  }

  function onOpenDefinition() {
    setDefinitionVisible(true)
  }

  const { title, subtitle } = uiTexts.question
  const { expertTitle, nextButton, subtitle: answerSubtitle } = uiTexts.answer
  const { yesButton, noButton } = uiTexts.common

  return (
    <>
      {definitionVisible && <div className={styles.BackDrop}></div>}
      <Container>
        <div className={styles.HeroContainer}>
          <button
            className={cx(
              styles.Button,
              questionNumber > 1 ? '' : styles.HiddenTopButton
            )}
            onClick={handlePreviousClicked}
          >
            <img
              src={navButton}
              className={styles.TopNavButton}
              alt="Nuoli vasemmalle"
            />
          </button>
          <img src={image} className={styles.HeroIllustration} alt={imageAlt} />
          <button
            className={cx(styles.Button, answer ? '' : styles.HiddenTopButton)}
            onClick={handleNextClicked}
          >
            <img
              src={navButton}
              className={cx(styles.TopNavButton, styles.RightNavButton)}
              alt="Nuoli oikealle"
            />
          </button>
        </div>
        <QuestionProgress {...{ lastQuestionNumber, questionNumber }} />
        <Statement
          questionNumber={questionNumber}
          onOpenDefinition={onOpenDefinition}
          definitionVisible={definitionVisible}
          onCloseDefinition={() => setDefinitionVisible(false)}
        />
        <div className={styles.ButtonContainer}>
          <Link to={SCROLL_TARGET_ID} smooth duration={700}>
            <Button
              className={cx(styles.PadRight)}
              type={
                answer === 'no' ? ButtonType.disabledSubmit : ButtonType.submit
              }
              text={yesButton}
              value={'yes'}
              onClick={handleAnswerClicked}
            />
          </Link>
          <Link to={SCROLL_TARGET_ID} smooth duration={700}>
            <Button
              className={styles.PadLeft}
              type={
                answer === 'yes' ? ButtonType.disabledCancel : ButtonType.cancel
              }
              text={noButton}
              value={'no'}
              onClick={handleAnswerClicked}
            />
          </Link>
        </div>

        {answer && (
          <>
            <DemographyBars answers={populationAnswers.answers} />
            <p className={styles.Subtitle}>{answerSubtitle}</p>
            <Button
              className={styles.NextButton}
              type={ButtonType.neutral}
              text={nextButton}
              onClick={handleNextClicked}
              icon={arrow}
              alt="Nuoli oikealle"
            />
          </>
        )}
        {!answer && (
          <>
            <h2 className={styles.AdditionalInfoHeader} id={SCROLL_TARGET_ID}>
              {title}
            </h2>
            <p className={styles.Subtitle}>{subtitle}</p>
          </>
        )}
        {answer && (
          <h2 className={styles.AdditionalInfoHeader}>{expertTitle}</h2>
        )}

        <ExpertGrid
          answers={props.expertAnswers}
          questionNumber={questionNumber}
        />
      </Container>
    </>
  )
}
