import React from 'react'
import styles from './App.module.scss'
import browserRoutes from 'src/routes/browser'
import NavigationContext from 'src/routes/NavigationContext'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Switch from './components/Switch'
import { questions, experts } from './util/questionData'
import Footer from './components/Footer'
import useNavigator from './util/useNavigator'
import useSessionStorage, { StorageType } from './util/useSessionStorage'
import { useReducer } from 'react'
import AnswersContext, { reducer } from 'src/util/AnswersContext'

interface AppWindow extends Window {
  yleApp: { [_: string]: { unmount: Function } }
}

declare const window: AppWindow

function App() {
  const { hash } = useLocation()
  const { routes, pop, push } = useNavigator()
  const currentRoute = routes[0]
  const [, setStoredAnswers] = useSessionStorage(StorageType.Answers)
  const [, setStoredBackgroundInformation] = useSessionStorage(
    StorageType.BackgroundInformation
  )
  const [state, dispatch] = useReducer(reducer, {
    answers: {},
    background: {},
    done: false,
  })

  useEffect(() => {
    if (window.yleApp) {
      window.yleApp['mahdoton-valinta'] = {
        unmount: function () {},
      }
    }
  }, [])

  useEffect(() => {
    setStoredAnswers(state.answers)
    setStoredBackgroundInformation(state.background)
  }, [state])

  useEffect(() => {
    if (hash?.includes('kysymys')) {
      const questionNumber = parseInt(hash.split('=')[1])
      if (questions.some((q) => q.questionNumber === questionNumber)) {
        push({
          name: browserRoutes.question,
          params: {
            questionNumber,
          },
        })
      }
    }
  }, [hash])

  useEffect(() => {
    questions.forEach((q) => {
      new Image().src = require('src/assets/images/' + q.illustration)
    })
    new Image().src = require('src/assets/images/Taustakysymykset.jpg')
    experts.forEach((e) => {
      new Image().src = require('src/assets/images/' + e.image)
    })
  }, [])

  return (
    <AnswersContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      <div className={styles.App}>
        <NavigationContext.Provider
          value={{
            current: currentRoute,
            routes,
            pop,
            push,
          }}
        >
          <div className={styles.Content}>
            <Switch route={currentRoute} />
          </div>
          <Footer />
        </NavigationContext.Provider>
      </div>
    </AnswersContext.Provider>
  )
}

export default App
