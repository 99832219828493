import { createContext } from 'react'

interface Action {
  type: 'answer' | 'background' | 'done'
  payload: any
}

type Answer = 'yes' | 'no'

interface State {
  answers: { [questionNumber: string]: Answer }
  background: {
    yearOfBirth?: string
    province?: string
    gender?: string
  }
  done: boolean
}

export interface Context extends State {
  dispatch: (action: Action) => void
}

const AnswersContext = createContext<Context>({
  answers: {},
  background: {},
  dispatch: () => {},
  done: false,
})

export function reducer(state: State, action: Action) {
  const { payload, type } = action

  switch (type) {
    case 'answer':
      return {
        ...state,
        answers: {
          ...state.answers,
          [payload.questionNumber]: payload.answer,
        },
      }
    case 'background':
      return {
        ...state,
        background: payload.background,
      }
    case 'done':
      return {
        ...state,
        done: true,
      }
    default:
      return state
  }
}

export default AnswersContext
