import env from '../env'

const Routes = {
  answer: `${env.appUrl}/api/v1/answers`,
}

export const postAnswers = (
  data: {
    questionNumber: number
    answer: 'yes' | 'no'
  }[]
) => {
  return postJson(Routes.answer, JSON.stringify(data))
}
const postJson = (url: string, data: BodyInit): Promise<string> => {
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: data,
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.statusText
  })
}

export default { Routes }
